<template>
  <qvoButton text class="header-button" @click="logout">Logout</qvoButton>
</template>

<script>
/**
 * @fileoverview Profile Icon
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import qvoButton from "@/qux-common/qux22/components/molecules/button";

export default {
  components: {
    qvoButton,
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-button.v-btn.v-size--default{
  &::v-deep {
    .label {
      color: white;
    }
  }
}
.header-button.v-btn.v-size--default:hover{
  &::v-deep {
    .label {
      color: $qvo-color-blue-400;
;
    }
  }
}
</style>