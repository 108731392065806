<template>
  <div class="nav-group">
    <p v-if="navExpanded" class="caption">{{ name }}</p>
    <navLink
      v-on="$listeners"
      v-for="link in links"
      :key="link.name"
      :name="link.name"
      :links="link.links"
      :href="link.href"
      :hash="link.hash"
      :icon="link.icon"
      :navExpanded="navExpanded"
      :activeNav="activeNav"
    />
  </div>
</template>

<script>
/**
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import navLink from "./nav-link";

export default {
  name: "NavigationGroup",
  components: { navLink },
  props: {
    activeNav: {
      type: String,
    },
    name: {
      type: String,
    },
    links: {
      type: Array,
    },
    navExpanded: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-group {
  .caption {
    color: $qvo-color-grey-500;
    text-transform: uppercase;
  }
}
</style>