<template>
  <div class="layout">
    <topNav />
    <sideNav v-show="!navHidden" />
    <router-view :style="`width: calc(100vw - ${panelWidth}px)`" />
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import sideNav from "@/components/side-nav";
import topNav from "@/components/top-nav";

export default {
  components: {
    sideNav,
    topNav,
  },
  computed: {
    ...mapGetters({
      panelWidth: "getTotalPanelWidth",
      navHidden: "getNavHidden",
    }),
  },
};
</script>
  
<style lang="scss">
html,
body,
.v-application {
  overflow: hidden;
  height: 100vh;
}

.v-application .v-application--wrap .layout {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header header"
    "sidenav content right-panel";
  .top-nav {
    z-index: 200;
  }
  .navigation-wrapper {
    z-index: 100;
  }
}
</style>