<template>
  <div class="navigation-wrapper" :class="navExpanded && 'expanded'">
    <textInput
      v-if="showSearch"
      placeholder="Search"
      prepend-inner-icon="mdi-magnify"
    />
    <nav>
      <navGroup
        v-on="$listeners"
        v-for="group in navData"
        :key="group.name"
        :name="group.name"
        :links="group.links"
        :navExpanded="navExpanded"
        :activeNav="activeNav"
      />
    </nav>
    <slot class="footer" name="footer" />
  </div>
</template>

<script>
/**
 * @fileoverview Common component for global navigation
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import textInput from "@/qux-common/qux22/components/molecules/input";

import navGroup from "./nav-group";

export default {
  name: "GlobalNavigation",
  components: { textInput, navGroup },
  props: {
    navData: {
      type: Array,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    navExpanded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    activeNav() {
      const path = this.$route.path;
      let matchedPaths = [];
      for (const group of this.navData) {
        for (const nav of group.links) {
          if (nav.links) {
            for (const subnav of nav.links) {
              if (path.includes(subnav.href)) matchedPaths.push(subnav.href);
            }
          }
          if (path.includes(nav.href)) matchedPaths.push(nav.href);
        }
      }

      if (matchedPaths.length === 0) return "/";

      // return the longest matching path
      matchedPaths.sort((a, b) => {
        return b.length - a.length;
      });

      return matchedPaths[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  width: 85px;
  background: $qvo-color-blue-100;
  padding: $qvo-spacing-8;
  box-shadow: $qvo-shadow-2;
  transition: width 200ms;
  &.expanded {
    width: 256px;
    padding: $qvo-spacing-24;
  }
}
nav {
  &::v-deep {
    .button.v-btn:not(.v-btn--round).v-btn--text {
      width: 100%;
      border: unset;
      padding: 5px 16px;
      margin: 4px 0;
      color: $qvo-color-blue-200;
      &.is-active-nav {
        background-color: $qvo-color-blue-200;
        &:before {
          opacity: 1;
        }
        &:hover:before {
          opacity: 1;
        }
        .v-btn__content {
          .label,
          .right-icon {
            color: $qvo-color-blue-400;
          }
        }
      }
      .v-btn__content {
        justify-content: space-between;
        width: 100%;
        text-transform: none;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        .label,
        .right-icon {
          color: $qvo-color-grey-600;
        }
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .subnav {
      .v-btn__content {
        padding-left: $qvo-spacing-16;
      }
    }
  }
}
</style>