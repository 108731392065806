<template>
  <section class="top-nav">
    <div>
      <v-btn v-show="!navHidden" icon @click="toggleNav" class="menu-button"
        ><v-icon>mdi-menu</v-icon></v-btn
      >
    </div>
    <div class="title">
      <h1 class="name"><a href="/clients">Interaction</a></h1>
      <p v-if="environment !== 'production'" class="subtitle-1 environment">
        {{ environment }}
      </p>
    </div>
    <textInput
      class="search-bar"
      placeholder="Search"
      prepend-inner-icon="mdi-magnify"
    />
    <dropdown
      v-if="availableTenants && availableTenants.length > 1"
      :items="availableTenants"
      :menuZIndex=300
      :value="activeTenantId"
      @change="[setActiveTenantId($event)]"
      placeholder="Select Tenant"
    />
    <dropdown
      v-if="availableClients && availableClients.length > 1"
      :items="availableClients"
      :menuZIndex=300
      :value="activeClientId"
      @change="[setActiveClientId($event)]"
      placeholder="Select Client"
    />
    <profileIcon />
  </section>
</template>
  
  <script>
/**
 * @fileoverview Top Navigation
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import { mapGetters } from "vuex";

import textInput from "@/qux-common/qux22/components/molecules/input";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import profileIcon from "@/components/profile-icon";

export default {
  components: {
    profileIcon,
    textInput,
    dropdown,
  },
  
  methods: {
    setActiveClientId(value) {
      this.$store.dispatch("setActiveClientId", value);
      this.$store.dispatch("retrieveTaskSummary", { ClientId: value});
    },
    setActiveTenantId(value) {
      this.$store.dispatch("setActiveTenant", value);
    },
    toggleNav() {
      this.$store.dispatch("toggleNav");
    },
  },
  computed: {
    availableClients() {
      const clients =
        this.clientList &&
        this.clientList.map((client) => ({
          text: `${client.clientId} - ${client.clientLabel}`,
          value: client.clientId,
        }));
      if (clients) {
        clients.sort((a, b) =>
          a.text > b.text ? 1 : b.text > a.text ? -1 : 0
        );
        if(this.activeClientId === '' && clients.length >= 1) { 
          this.setActiveClientId(clients[0].value);
        }
      } 
      return clients;
    },
    availableTenants() {
      const tenants =
        this.tenantList &&
        this.tenantList.tenantList &&
        this.tenantList.tenantList.map((tenant) => ({
          text: tenant.tenantLabel,
          value: tenant.tenantId,
        }));
      if (tenants) {
        tenants.sort((a, b) =>
          a.text > b.text ? 1 : b.text > a.text ? -1 : 0
        );
      }
      return tenants;
    },
    ...mapGetters({
      environment: "getEnvironment",
      navHidden: "getNavHidden",
      tenantList: "getTenantList",
      activeTenantId: "getActiveTenantId",
      clientList: "getClientList",
      activeClientId: "getActiveClientId",
    }),
  },
};
</script>
  
  <style lang="scss" scoped>
.top-nav {
  grid-area: header;
  display: grid;
  grid-template-columns: auto minmax(auto, 10em) 1fr auto auto auto;
  column-gap: 1em;
  width: 100vw;
  align-items: center;
  padding: 6px 24px;
  position: static;
  background: $qvo-color-blue-400;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.18);
  .menu-button {
    .v-icon {
      color: $qvo-color-grey-000;
    }
  }
  .title {
    display: flex;
    align-items: baseline;
    gap: $qvo-spacing-16;
    .name {
      font-size: 1.875rem;
      line-height: 0.8;
      font-weight: 700;
      a {
        font-family: $qvo-serif-font-family;
        color: $qvo-color-grey-000;
        text-decoration: none;
      }
    }
    .environment {
      color: $qvo-color-grey-000;
      text-transform: uppercase;
    }
  }

  .search-bar {
    visibility: hidden;
    /* make invisible for now as its vaporware */
    min-width: 10em;
    max-width: 35%;

    &::v-deep {
      .label {
        margin: 0;
      }
    }
  }
}
</style>